import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import { ICustomerDefinedDataState } from '@/modules/customer-defined-data/store/customer-defined-data.module.model';
import { IDataImportState } from '@/modules/data-import/store/data-import';
import { IContinuousPricingState } from '@/modules/route-management/store/modules/continuous-pricing.module';
import { ICalendarState } from '@/modules/shared/store/modules/Calendar';
import { ITagsState } from '@/modules/tags';
import { IAuthState } from '@/store/modules/auth.module';
import { IConfigurationState } from '@/store/modules/configuration.module';
import { IControlState } from '@/store/modules/control.module';
import { ICustomerSettingsState } from '@/store/modules/customer-settings.module';
import { IFlightReviewState } from '@/store/modules/flight-review.module';
import { IFlightState } from '@/store/modules/flight.module';
import { IMarketInfoState } from '@/store/modules/market-info.module';
import { IOptimisationProfilesState } from '@/store/modules/optimisation-profiles.module';
import { IRouteGroupsState } from '@/store/modules/route-groups.module';
import { IRouteManagementState } from '@/store/modules/route-management.module';
import { IUserConfigState } from '@/store/modules/user-config.module';

// Root state, add your module type here to get type checking and autocomplete
// when using a module in a component / view.
export interface IRootState {
  auth: IAuthState;
  calendar: ICalendarState;
  configuration: IConfigurationState;
  continuousPricing: IContinuousPricingState;
  control: IControlState;
  customerSettings: ICustomerSettingsState;
  dataImport: IDataImportState;
  flight: IFlightState;
  flightReview: IFlightReviewState;
  marketInfo: IMarketInfoState;
  optimisationProfiles: IOptimisationProfilesState;
  routeGroups: IRouteGroupsState;
  routeManagement: IRouteManagementState;
  tags: ITagsState;
  userConfig: IUserConfigState;
  customerDefinedData: ICustomerDefinedDataState;
}

// Throw a raw error instead of a vuex module decorator wrapped error
// https://github.com/championswimmer/vuex-module-decorators/issues/224
config.rawError = true;

// Declare empty store first, dynamically register all modules later.
export const store = createStore<IRootState>({
  strict: false,
});
