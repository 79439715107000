import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-enterprise';

import { ColumnId } from '@/models/enums/grid';
import { FlightViewLegCabinInfo } from '@/models/FlightModel';
import { UpdateOverbookingAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-overbooking-autopilot-action';
import { i18n } from '@/plugins/i18n';
import { FlightModule } from '@/store/modules/flight.module';
import { OverbookingModule } from '@/store/modules/overbooking.module';

const { t } = i18n.global;

export const GrossAvailabilityColumn: ColDef = {
  colId: ColumnId.GrossAvailability,
  headerName: t('gross_availability_short'),
  field: 'grossAvailability',
  type: 'numericColumn',
  minWidth: 50,
  width: 50,
  headerTooltip: t('gross_availability'),
};

export const NetAvailabilityColumn: ColDef = {
  colId: ColumnId.NetAvailability,
  headerName: t('net_availability_short'),
  field: 'netAvailability',
  type: 'numericColumn',
  minWidth: 50,
  width: 50,
  headerTooltip: t('net_availability'),
};

export const LoadFactorColumn: ColDef = {
  colId: ColumnId.LoadFactor,
  headerName: t('authorized_capacity_load_factor_short'),
  headerTooltip: t('authorized_capacity_load_factor'),
  field: 'capturedLoadFactor',
  type: 'numericColumn',
  minWidth: 60,
  width: 60,
  cellRenderer: 'LoadFactorRenderer',
  cellClass: 'marginless-cell border border-theme-primary flex',
  cellRendererParams: (params: ICellRendererParams) => {
    const data = params.data as FlightViewLegCabinInfo;

    return {
      realtimeLoadFactor: data.loadFactor,
    };
  },
};

export const LegCabinCapacityLoadfactorColumn: ColDef = {
  colId: ColumnId.LegCabinCapacityLoadfactor,
  headerName: t('saleable_capacity_load_factor_short'),
  headerTooltip: t('saleable_capacity_load_factor'),
  field: 'capturedCapacityLoadFactor',
  type: 'numericColumn',
  minWidth: 60,
  width: 60,
  cellRenderer: 'CapacityLoadFactorRenderer',
  cellClass: 'marginless-cell border border-theme-primary flex',
  cellRendererParams: (params: ICellRendererParams) => {
    const data = params.data as FlightViewLegCabinInfo;

    return {
      realtimeCapacityLoadFactor: data.capacityLoadFactor,
    };
  },
};

export const LegCabinBookingsColumn: ColDef = {
  colId: ColumnId.LegCabinBookings,
  headerName: t('bookings_short'),
  field: 'updatedBookings',
  type: 'numericColumn',
  minWidth: 80,
  width: 80,
  headerTooltip: t('bookings'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: 'bookings',
  },
};

export const UpdatedAuthorizedCapacityColumn: ColDef = {
  colId: ColumnId.UpdatedAuthorizedCapcity,
  cellClass: ({ data }) => `data-test-a-cap-cell-cabin-${data.cabinCode.toLowerCase()}`,
  headerName: t('authorized_capacity_short'),
  field: 'updatedAuthorizedCapacity',
  type: 'numericColumn',
  minWidth: 60,
  width: 60,
  headerTooltip: t('authorized_capacity'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: 'authorizedCapacity',
  },
};

export const generateLegCabinCapacityColumn = (isEditable: boolean): ColDef => {
  const legCapColDef: ColDef = {
    colId: ColumnId.LegCabinCapacity,
    cellClass: ({ data }) => `ag-right-aligned-cell data-test-s-cap-cell-cabin-${data.cabinCode.toLowerCase()}`,
    headerName: t('saleable_capacity_short'),
    field: 'saleableCapacity',
    type: 'numericColumn',
    menuTabs: ['filterMenuTab'],
    filter: 'agNumberColumnFilter',
    minWidth: 65,
    width: 65,
    headerTooltip: t('saleable_capacity'),
  };

  if (isEditable) {
    legCapColDef.cellRenderer = 'GridCapacityRenderer';
    legCapColDef.cellClass = 'marginless-cell';
  }

  return legCapColDef;
};

export const generateOverbookingFactorColumn = (isDeparted: boolean): ColDef => ({
  colId: ColumnId.OverbookingFactor,
  headerName: t('overbooking_factor_short'),
  field: 'overbookingFactor',
  minWidth: 70,
  width: 70,
  type: 'numericColumn',
  headerTooltip: t('overbooking_factor'),
  cellRenderer: 'GridOverbookingFactorRenderer',
  cellRendererParams: { isDeparted },
  cellClass: 'marginless-cell',
});

export const recommendedOverbookingFactorColumn: ColDef = {
  colId: ColumnId.RecommendedOverbooking,
  headerName: t('details.recommended_overbooking_factor_short'),
  field: 'recommendedOverbookingFactor',
  width: 70,
  minWidth: 70,
  type: 'numericColumn',
  headerTooltip: t('details.recommended_overbooking_factor'),
  cellRenderer: 'GridRecommendedOverbookingFactorRenderer',
  cellClass: 'marginless-cell',
  requiredPermission(params) {
    return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
  },
};

export type OverbookingAutopilotColumnCallback = ({
  value,
  cabinCode,
  flightLineId,
  legNumber,
}: {
  value: boolean;
  cabinCode: string;
  flightLineId: number;
  legNumber: number;
}) => void;

const overbookingAutopilotColumnCallback: OverbookingAutopilotColumnCallback = ({ value, cabinCode, flightLineId, legNumber }) => {
  OverbookingModule.setOverbookingAutopilot(value);
  const action = new UpdateOverbookingAutopilotAction(cabinCode, legNumber);
  action.setPayload({
    actionType: action.actionType,
    cabinCode,
    value,
    legNumber,
  });
  FlightModule.addOverbookingAutopilotAction({
    action,
    flightLineId,
  });
};

export const OverbookingAutopilotColumn: ColDef = {
  colId: ColumnId.OverbookingAutopilot,
  headerName: t('details.overbooking_autopilot_short'),
  field: 'overbookingAutopilot',
  width: 70,
  minWidth: 70,
  cellRenderer: 'GridSwitchRenderer',
  cellRendererParams: (params: ICellRendererParams) => {
    const data = params.data as FlightViewLegCabinInfo;
    return {
      onChange: ({ value, cabinCode, flightLineId }: { value: boolean; cabinCode: string; flightLineId: number }) =>
        overbookingAutopilotColumnCallback({ value, cabinCode, flightLineId, legNumber: data.legId }),
    };
  },
  headerTooltip: t('details.overbooking_autopilot'),
  requiredPermission(params) {
    return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
  },
};

export const ExpectedNoShowsColumn: ColDef = {
  colId: ColumnId.ExpectedNoShows,
  headerName: t('details.expected_no_shows_short'),
  field: 'expectedNoShows',
  minWidth: 50,
  width: 50,
  filter: 'agNumberColumnFilter',
  type: 'numericColumn',
  cellClass: 'marginless-cell ag-right-aligned-cell',
  headerTooltip: t('details.expected_no_shows'),
  requiredPermission(params) {
    return !!params.customerSettings.hasOverbookingRecommendationsEnabled;
  },
};

export const OperationCapacityColumn: ColDef = {
  colId: ColumnId.OperationCapacity,
  headerName: t('operating_capacity_short'),
  field: 'operatingCapacity',
  type: 'numericColumn',
  minWidth: 55,
  width: 55,
  headerTooltip: t('operating_capacity'),
};

export const LegCabinAuthorizedCapcityColumn: ColDef = {
  colId: ColumnId.LegCabinAuthorizedCapacity,
  headerName: t('authorized_capacity_short'),
  field: 'authorizedCapacity',
  type: 'numericColumn',
  filter: 'agNumberColumnFilter',
  menuTabs: ['filterMenuTab'],
  width: 45,
  headerTooltip: t('authorized_capacity'),
};

export const LegCabinOverbookingNumberColumn: ColDef = {
  colId: ColumnId.LegCabinOverbookingFactor,
  headerName: t('overbooking_number_short'),
  field: 'ovb',
  minWidth: 60,
  width: 60,
  type: 'numericColumn',
  valueGetter: (params: ValueGetterParams) =>
    (params.data as FlightViewLegCabinInfo).balancedAdjustment + (params.data as FlightViewLegCabinInfo).unbalancedAdjustment,
  headerTooltip: t('overbooking_number'),
};

export const LegCabinBlockSpaceColumn: ColDef = {
  colId: ColumnId.LegCabinBlockSpace,
  headerName: t('block_space_short'),
  field: 'cabinAgreements',
  minWidth: 60,
  width: 60,
  type: 'numericColumn',
  headerTooltip: t('block_space'),
  cellRenderer: 'GridBlockSpaceRenderer',
};

export const LegCabinOriginDestinationColumn: ColDef = {
  colId: ColumnId.LegCabinOriginDestination,
  headerName: t('leg'),
  valueFormatter: (params: any) => {
    if ((params.data as FlightViewLegCabinInfo).cabinIndex === 0) {
      return `${(params.data as FlightViewLegCabinInfo).origin}-${(params.data as FlightViewLegCabinInfo).destination}`;
    } else {
      return '';
    }
  },
  minWidth: 85,
  width: 85,
};
