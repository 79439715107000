import { cloneDeep, isEmpty, isFinite, isNil } from 'lodash-es';
import moment from 'moment';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { CabinStructure } from '@/modules/api/application/application-contracts';
import {
  FlightFilterModel,
  FlightLegModel,
  FlightLineCabin,
  FlightLineCabinClass,
  FlightLineModel,
  PostDepartureCabin,
  SourceFlight,
} from '@/modules/api/flight/flight-contracts';
import { ondsService as OndsService } from '@/modules/api/flight/onds-service';
import { HTTPStatusCode } from '@/modules/api/status-codes';
import { AddOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-optimization-profile-level-action';
import { AddShadowOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-shadow-optimization-profile-level-action';
import { ApplyRecommendedAUAction } from '@/modules/flight-actions/actions/cabin-actions/apply-recommended-au-action';
import { CorrectAuStructureAction } from '@/modules/flight-actions/actions/cabin-actions/correct-au-structure-action';
import {
  SetRecommendedOverbookingRiskPercentageAction,
  SetRecommendedOverbookingRiskPercentagePayload,
} from '@/modules/flight-actions/actions/cabin-actions/set-recommended-overbooking-risk-percentage-action';
import { SwapOptimizationTacticAction } from '@/modules/flight-actions/actions/cabin-actions/swap-optimization-tactic-action';
import { UpdateAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-autopilot-action';
import { UpdateOverbookingAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-overbooking-autopilot-action';
import { AddTagsAction } from '@/modules/flight-actions/actions/definitions/add-tags-action';
import { RemoveTagsAction } from '@/modules/flight-actions/actions/definitions/remove-tags-action';
import {
  BaseFlightActionPayload,
  ClassValue,
  DistributedClasses,
  FlightAction,
  FlightActionType,
} from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionsModule } from '@/modules/flight-actions/store/modules/flight-actions.module';
import { RouteClusterAssignments } from '@/modules/route-management/api/route-clusters/route-cluster-contracts';
import { routeClusterService } from '@/modules/route-management/api/route-clusters/route-cluster-service';
import { MessageService } from '@/modules/shared';
import { ITag } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';
import { store } from '@/store';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { ConfigurationModule } from '@/store/modules/configuration.module';
import { ControlModule } from '@/store/modules/control.module';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';
import { MarketInfoModule } from '@/store/modules/market-info.module';

import { OverbookingModule } from './overbooking.module';

const { t } = i18n.global;

// State definition
export interface IFlightState {
  isLoading: boolean;
  isSaving: boolean;
  isRecalculating: boolean;
  flight: FlightLineModel[] | null;
  updatedFlight: FlightLineModel[] | null;
  filter: FlightFilterModel | null;
  selectedFlightLine: FlightLineModel | null;
  selectedFlightLineCode: string;
  flightActions: Map<number, BaseFlightActionPayload[]>;
}

@Module({ dynamic: true, store, name: 'flight', namespaced: true })
class Flight extends VuexModule implements IFlightState {
  // Default state
  public isLoading = false;
  public isSaving = false;
  public isRecalculating = false;
  public isNewClusterLevelAssigned = false;
  public updatedFlight: FlightLineModel[] | null = null;
  public flight: FlightLineModel[] | null = null;
  public filter: FlightFilterModel | null = null;
  public hasAttachedOptimisationProfileLevel = false;
  public isAuditSidebarOpen = false;
  public isFlightUpdated = false;
  public selectedFlightLineCode = '';
  public sourceFlight: SourceFlight | null = null;
  public carrierCode: string | null = null;
  public hasValidAuStructure = true;
  public updatedBookings: FlightLineModel | null = null;
  public selectedFlightLine: FlightLineModel | null = null;
  public flightActions: Map<number, FlightAction<any>[]> = new Map();
  public isFlightDeparted = false;

  // ACTIONS
  @Action
  public setIsRecalculatingAction(payload: boolean): void {
    this.setIsRecalculatingMutation(payload);
  }

  @Action
  public setSourceFlightAction(payload: SourceFlight): void {
    this.setSourceFlightState(payload);
  }

  @Action
  public clearFlight(): void {
    this.setFlight(null);
    this.clearFlightActions();
    this.setCarrierCode();
  }

  @Action
  public getFlightByLegId(legId: number) {
    return this.flight.find((flightLine) => flightLine.legs?.find((leg: FlightLegModel) => leg.id === legId));
  }

  @Action
  public async getFlight(filter: FlightFilterModel) {
    this.clearFlight();
    this.setCarrierCode(filter.carrierCode);

    try {
      this.setLoadingState(true);
      this.setAttachedOptimisationProfileLevel(false);

      const flight: FlightLineModel[] = await OndsService.get(filter);

      if (!isEmpty(flight)) {
        this.checkAuStructure({
          flightLine: flight[0],
          cabins: AppSettingsModule.inventoryConfigurationProperties.cabins,
        });

        // TODO: can be removed once we populate route_id field on ond_data
        const flightLineRoutes = cloneDeep(
          MarketInfoModule.marketInfo.filter((route) => flight.map((fl) => fl.flightPath).includes(route.flightPath)),
        );

        const routeClusterAssignmentPromises = flightLineRoutes.map((route) => routeClusterService.get(route.id));

        let routeClusterAssignments: RouteClusterAssignments[] = await Promise.all(routeClusterAssignmentPromises);

        // remove empty responses
        routeClusterAssignments = routeClusterAssignments.filter((routeClusterAssignment) => isFinite(routeClusterAssignment.routeId));

        // Apply cluster assignments to the flightlines
        for (const clusterAssigment of routeClusterAssignments) {
          // Find the route for the cluster assignment
          const matchedRoute = flightLineRoutes.find((r) => r.id === clusterAssigment.routeId);

          // Add the flightpath to the cluster assignment
          if (!isNil(matchedRoute)) {
            clusterAssigment.flightPath = matchedRoute.flightPath;
          } else {
            console.warn(`[src/store/modules/flight.ts] No route found for cluster assignment with routeId: ${clusterAssigment.routeId}`);
          }

          // Get the flightline that matches the route cluster assignment
          const matchingFlightLine = flight.find((fl) => fl.flightPath === clusterAssigment.flightPath);

          // For each cabin in the flightline, set the cluster options
          if (!isNil(matchingFlightLine)) {
            for (const cabin of clusterAssigment.cabins) {
              const matchingCabin = FlightService.getMatchedCabin(matchingFlightLine, cabin.cabinCode);

              if (!isNil(matchingCabin)) {
                matchingCabin.clusterOptions = cabin.clusters;
              }
            }
          }
        }

        this.setFlight(flight);
      }
    } catch (error) {
      console.warn('error fetching flight ', error);
    } finally {
      this.setLoadingState(false);
    }
  }

  @Action
  public async getDepartedFlight(filter: FlightFilterModel) {
    this.clearFlight();
    this.setCarrierCode(filter.carrierCode);

    try {
      this.setLoadingState(true);
      const response = await OndsService.getDeparted(filter);

      if (!isEmpty(response)) {
        const sortedResponse = await Promise.all(response).then((resolvedOnds: FlightLineModel[]) =>
          resolvedOnds.map((fl: FlightLineModel) => {
            if (fl.postDeparture && fl.postDeparture.cabins?.length > 0) {
              fl.postDeparture.cabins.sort(
                (a: PostDepartureCabin, b: PostDepartureCabin) =>
                  AppSettingsModule.inventoryConfigurationProperties.cabins.find(({ code }) => a.code === code)?.order -
                  AppSettingsModule.inventoryConfigurationProperties.cabins.find(({ code }) => b.code === code)?.order,
              );
              fl.postDeparture.cabins.forEach((cabin: PostDepartureCabin) => {
                cabin.classes.sort(
                  (a: FlightLineCabinClass, b: FlightLineCabinClass) =>
                    AppSettingsModule.inventoryConfigurationProperties.cabins
                      .find(({ code }) => cabin.code === code)
                      .classes.find(({ code }) => a.code === code).order -
                    AppSettingsModule.inventoryConfigurationProperties.cabins
                      .find(({ code }) => cabin.code === code)
                      .classes.find(({ code }) => b.code === code).order,
                );
              });
            }
            return fl;
          }),
        );
        this.setFlight(sortedResponse);
      }
    } catch (error) {
      console.warn('error fetching flight ', error);
    } finally {
      this.setLoadingState(false);
    }
  }

  @Action
  public checkAuStructure(payload: { flightLine: FlightLineModel | null; cabins: CabinStructure[] }) {
    try {
      const flightLine = payload.flightLine;
      const cabins = payload.cabins;

      if (!flightLine?.cabins || !cabins) {
        return;
      }

      const validAuStructure = flightLine.cabins.every((cabin) => {
        const inventoryCabin = cabins.find((inventoryCabin) => inventoryCabin.code === cabin.code);
        if (isNil(inventoryCabin)) {
          return true;
        }
        // Filter out if class has excludeInLAF set to true
        const ondClasses = cabin.classes.filter((ondClass) =>
          inventoryCabin.classes.find((inventoryClass) => inventoryClass.code === ondClass.code && !inventoryClass.excludeInLAF),
        );

        return ondClasses.every((ondClass, index) => {
          const au = ondClass.isAuthorizationUnitsUpdated ? ondClass.updatedAuthorizationUnits : ondClass.authorizationUnits;
          const parentClass = cabin.classes[index - 1];
          const parentAU = parentClass
            ? parentClass.isAuthorizationUnitsUpdated
              ? parentClass.updatedAuthorizationUnits
              : parentClass.authorizationUnits
            : au;

          return parentAU >= au;
        });
      });
      this.setValidAuStructure(validAuStructure);
    } catch (err) {
      console.warn(err);
    }
  }

  @Action
  public async checkFlightAuStructure(cabinCode: string) {
    const action = new CorrectAuStructureAction(cabinCode);
    this.addAction({
      action,
      flightLineId: this.selectedFlightLine.id,
    });

    await this.saveFlight({
      preview: true,
      updateWorkingFlight: true,
    });
  }

  @Action
  public selectFlightLine(flightLineCode: string) {
    this.setSelectedFlightLine(flightLineCode);
    /**
     * Always set the sourceFlight, even if it is undefined.
     * Setting the sourceFlight to undefined will clear the state
     */
    this.setSourceFlightAction(this.selectedFlightLine?.sourceFlight);
  }

  @Action
  public resetModified() {
    this.setFlightLineRowModifiedToFalse();
    this.setFlightLineTagModifiedToFalse();
    this.resetUpdatedFlight();
  }

  @Action
  public toggleAuditSidebarVisibility(open: boolean) {
    this.toggleSidebar({ open });
  }

  @Action
  public async updateFlight() {
    this.resetModified();

    this.setIsUpFlightUpdated(true);
  }

  @Action({ commit: 'dateModifiedUpdated' })
  public updateDateModified(payload: { date: string; flightId: number }) {
    return payload;
  }

  @Action
  public async applyFlightActions(payload: { preview: boolean }): Promise<FlightLineModel[]> {
    const actions: Promise<FlightLineModel>[] = [];
    FlightModule.flightActions.forEach((value, key) => {
      actions.push(
        FlightActionsModule.applyOne({
          actions: value,
          flightLine: FlightModule.flight.find((flightLine) => flightLine.id === key),
          preview: payload.preview,
          // False because we update below when all requests are done, considering multi-legged flights
          updateWorkingFlight: false,
        }),
      );
    });

    const results = await Promise.all(actions);
    results.forEach((result: FlightLineModel) => {
      this.updateWorkingFlight(result);
    });

    return results;
  }

  @Action
  public async saveFlight(payload: { preview: boolean; updateWorkingFlight: boolean }): Promise<FlightLineModel[]> {
    this.setSavingState(true);

    const actions: Promise<FlightLineModel>[] = [];
    FlightModule.flightActions.forEach((value, key) => {
      actions.push(
        FlightActionsModule.applyOne({
          actions: value,
          flightLine: FlightModule.flight.find((flightLine) => flightLine.id === key),
          preview: payload.preview,
          // False because we update below when all requests are done, considering multi-legged flights
          updateWorkingFlight: payload.updateWorkingFlight,
        }),
      );
    });

    return Promise.all(actions)
      .then((results) => {
        let updatedFlight: FlightLineModel;

        results.forEach((result: any) => {
          if (result) {
            if (result && (result as FlightLineModel).cabins) {
              updatedFlight = result;
              const payload = {
                date: result.userDateModified,
                flightId: result.id,
              };

              this.updateDateModified(payload);
              this.updateWorkingFlight(updatedFlight);
            }
          }
        });

        ControlModule.updateFlightReviewUpdated(true);
        if (!payload.preview) {
          MessageService.success();
        }

        return results;
      })
      .catch((error: any) => {
        ControlModule.updateFlightReviewUpdated(false);

        if (error.response && error.response.status === HTTPStatusCode.Forbidden) {
          MessageService.error(t('flight_update_not_allowed'));
        } else {
          MessageService.generalError();
        }

        return [];
      })
      .finally(() => {
        this.resetModified();
        this.setSavingState(false);
      });
  }

  @Action
  public setFilter(payload: FlightFilterModel) {
    this.setFilterState(payload);
  }

  @Action
  public setLoading(payload: boolean) {
    this.setLoadingState(payload);
  }

  @Action
  public setNewClusterLevelAssigned(payload: boolean) {
    this.setNewClusterLevelAssignedState(payload);
  }

  // MUTATIONS
  @Mutation
  public setNewClusterLevelAssignedState(payload: boolean) {
    this.isNewClusterLevelAssigned = payload;
  }

  @Mutation
  public setFilterState(payload: FlightFilterModel) {
    this.filter = payload;
  }

  @Mutation
  public setCarrierCode(payload?: string) {
    this.carrierCode = payload ? payload : null;
  }

  @Mutation
  public dateModifiedUpdated(payload: { date: string; flightId: number }) {
    let modifiedFlightLine = this.flight.find((fl) => fl.id === payload.flightId);

    if (modifiedFlightLine) {
      modifiedFlightLine = {
        ...modifiedFlightLine,
        userDateModified: payload.date,
      };
    }

    this.flight = [
      ...this.flight.map((fl) => {
        if (fl.id === modifiedFlightLine?.id) {
          fl = { ...modifiedFlightLine };
        }
        return fl;
      }),
    ];
  }

  @Mutation
  public updateWorkingFlight(payload: FlightLineModel): void {
    const flightLine = this.flight.find((fl) => fl.id === payload.id);
    if (flightLine) {
      const updatedLegs = flightLine.legs?.map((leg) => {
        const updatedLeg = payload.legs?.find((payloadLeg) => payloadLeg.id === leg.id) ?? leg;

        return {
          ...updatedLeg,
          departureDate: typeof updatedLeg.departureDate === 'string' ? moment(updatedLeg.departureDate) : updatedLeg.departureDate,
        };
      });

      flightLine.hasRealTimeBookings = payload.hasRealTimeBookings;
      flightLine.tags = payload.tags;
      flightLine.linkedClassRuleId = payload.linkedClassRuleId;
      flightLine.rivalRuleId = payload.rivalRuleId;

      // Make sure we update the flight line at the right index
      const index = this.flight.findIndex((fl) => fl.id === flightLine.id);

      if (!isEmpty(flightLine.cabins)) {
        const cabins = flightLine.cabins?.map((cabin: FlightLineCabin) => {
          const payloadCabin = payload.cabins?.find((previewCabin) => previewCabin.code === cabin.code);
          if (payloadCabin) {
            // Some values are not always present when not defined, so update these explicitly
            cabin = {
              ...cabin,
              ...payloadCabin,
              optimisationTactics: payloadCabin.optimisationTactics,
              optimisationProfileLevel: payloadCabin.optimisationProfileLevel,
              optimisationProfileLevelId: payloadCabin.optimisationProfileLevelId,
              recommendedLowestAvailableFareClass: payloadCabin.recommendedLowestAvailableFareClass,
              shadowOptimisationTactics: payloadCabin.shadowOptimisationTactics,
              shadowOptimisationProfileLevel: payloadCabin.shadowOptimisationProfileLevel,
              shadowOptimisationProfileLevelId: payloadCabin.shadowOptimisationProfileLevelId,
              shadowRecommendedLowestAvailableFareClass: payloadCabin.shadowRecommendedLowestAvailableFareClass,
            };

            if (!payloadCabin.cluster) {
              delete cabin.cluster;
              delete cabin.performanceLabel;
              delete cabin.performanceScore;
            }
          }
          return cabin;
        });

        this.flight.splice(index, 1, {
          ...flightLine,
          sourceFlight: payload.sourceFlight,
          cabins,
          legs: updatedLegs,
        });
      } else {
        this.flight.splice(index, 1, {
          ...flightLine,
          legs: updatedLegs,
        });
      }
    }
  }

  @Mutation
  public setSourceFlightState(payload: SourceFlight | null): void {
    this.sourceFlight = payload;
  }

  @Mutation
  public setSelectedFlightLine(flightLineCode: string) {
    this.selectedFlightLineCode = flightLineCode;
    this.selectedFlightLine = this.flight.find((fl) => fl.flightLine === this.selectedFlightLineCode);
  }

  @Mutation
  public toggleSidebar(payload: { open: boolean }) {
    this.isAuditSidebarOpen = payload.open;
  }

  @Mutation
  public setIsUpFlightUpdated(payload: boolean) {
    this.isFlightUpdated = payload;
  }

  @Mutation
  public setFlight(flightPayload: FlightLineModel[] | null): void {
    if (flightPayload === null) {
      this.flight = null;
      this.updatedFlight = null;
      return;
    }

    this.flight = flightPayload;
    if (this.flight?.length) {
      const captureDate = DateTimeService.getDate({
        date: ConfigurationModule.captureDate,
      });

      const flightDepartureDate = DateTimeService.getDate({
        date: this.flight[0].departureDate,
      });
      this.isFlightDeparted = flightDepartureDate.isBefore(captureDate);
    }
    this.updatedFlight = cloneDeep(flightPayload);
    this.selectedFlightLine = flightPayload[0];

    this.flight.forEach((flightLine) => {
      this.flightActions.set(flightLine.id, []);
    });
  }

  @Action
  public async addTag(payload: { flightLineId: number; selectedTag: ITag }) {
    if (this.flight) {
      const flightLineToAdjust = this.flight.find((fl) => fl.id === payload.flightLineId);

      if (flightLineToAdjust) {
        flightLineToAdjust.isModified = true;

        // TODO: Fix conversions
        const flightActionGroups = this.flightActions
          .get(this.selectedFlightLine.id)
          .filter((action: FlightAction<any>) => action.actionType === FlightActionType.tagsAdd) as unknown as AddTagsAction[];

        const action = new AddTagsAction(
          flightActionGroups.length > 0 ? [...flightActionGroups[0].value, payload.selectedTag] : [payload.selectedTag],
        );
        this.addAction({
          action,
          flightLineId: this.selectedFlightLine.id,
          joinAction: true,
        });
        this.addTagToSelectedFlightLine(payload.selectedTag);
      }
    }
  }

  @Action
  public async removeTag(payload: { flightLineId: number; index: number }) {
    if (this.flight) {
      const flightLineToAdjust = this.flight.find((fl) => fl.id === payload.flightLineId);
      if (flightLineToAdjust) {
        flightLineToAdjust.isModified = true;

        const action = new RemoveTagsAction({ tags: flightLineToAdjust.tags.splice(payload.index, 1), removeAll: false });
        this.addAction({
          action,
          flightLineId: this.selectedFlightLine.id,
          joinAction: true,
        });
      }
    }
  }

  @Action
  public async clearTags(payload: { flightLineId: number }) {
    if (this.flight) {
      const flightLineToAdjust = this.flight.find((fl) => fl.id === payload.flightLineId);
      if (flightLineToAdjust) {
        flightLineToAdjust.tags = [];
      }
    }
  }

  @Action
  public addAutopilotAction(payload: { action: UpdateAutopilotAction; flightLineId: number }) {
    const index = this.flightActions
      .get(payload.flightLineId)
      .findIndex(
        (action) =>
          action.actionType === FlightActionType.addOptimisationProfileLevel &&
          (action as UpdateAutopilotAction).cabinCode === payload.action.cabinCode,
      );

    if (index > -1) {
      const action = this.flightActions.get(payload.flightLineId).splice(index, 1)[0];
      (action as AddOptimizationProfileLevelAction).applyRecommendedAUs = payload.action.getPayload().value;

      const actions = [...this.flightActions.get(payload.flightLineId)];
      actions[index] = action;

      this.flightActions.set(payload.flightLineId, actions);
    } else if (index === -1 && payload.action.value) {
      const optimisationProfileLevel = this.selectedFlightLine.cabins.find(
        (cabin) => cabin.code === payload.action.cabinCode,
      ).optimisationProfileLevel;
      if (optimisationProfileLevel) {
        const optimisationProfileAction = new AddOptimizationProfileLevelAction(
          payload.action.cabinCode,
          payload.action.getPayload().value,
          this.selectedFlightLine.cabins.find((cabin) => cabin.code === payload.action.cabinCode).optimisationProfileLevel.id,
        );

        this.addAction({
          action: optimisationProfileAction,
          flightLineId: payload.flightLineId,
        });
      }
    }

    this.addAction(payload);
  }

  @Action
  public addOverbookingAutopilotAction(payload: { action: UpdateOverbookingAutopilotAction; flightLineId: number }): void {
    // If the overbooking autopilot action is set to 'on', we should apply the rOVB to the OVB
    if (payload.action.value) {
      const recommendedOverbookingRiskPercentageAction = new SetRecommendedOverbookingRiskPercentageAction(
        payload.action.cabinCode,
        payload.action.legNumber,
      );

      // Only apply recommendedOverbookingFactor if it has been set by the user
      if (OverbookingModule.selectedLegCabin?.recommendedOverbookingFactor) {
        const cabin = this.selectedFlightLine?.cabins?.find((cabin) => cabin.code === OverbookingModule.selectedLegCabin?.cabinCode);
        const recommendedOverbookingRiskPayload: SetRecommendedOverbookingRiskPercentagePayload = {
          actionType: recommendedOverbookingRiskPercentageAction.actionType,
          value: OverbookingModule.overbookingRiskPercentage as number,
          cabinCode: OverbookingModule.selectedLegCabin?.cabinCode as string,
          applyRecommendedAU: cabin?.autopilot as boolean,
          applyRecommendedOverbookingFactor: true,
          recommendedOverbookingFactor: OverbookingModule.selectedLegCabin.recommendedOverbookingFactor,
          legNumber: OverbookingModule.selectedLegCabin?.legId,
        };

        recommendedOverbookingRiskPercentageAction.setPayload(recommendedOverbookingRiskPayload);

        this.addMultiLegAction({
          action: recommendedOverbookingRiskPercentageAction,
        });
      }

      const selectedCabin: FlightLineCabin | undefined = this.selectedFlightLine?.cabins?.find(
        (selectedCabin: FlightLineCabin) => selectedCabin.code === payload.action.cabinCode,
      );
      // And if the selectedFlightLine has autopilot for OP levels, we should also apply the rAUs
      if (selectedCabin?.autopilot) {
        const applyRecommendedAUAction = new ApplyRecommendedAUAction(payload.action.cabinCode);
        this.addAction({
          action: applyRecommendedAUAction,
          flightLineId: payload.flightLineId,
        });
      }
    }
    // And finally apply the overbooking autopilot action
    this.addMultiLegAction(payload);
  }

  @Action addSwapOptimisationProfileAction(payload: { cabinCode: string; flightLineId: number; autopilot: boolean }): void {
    const flightActions: FlightAction<any>[] | undefined = this.flightActions.get(payload.flightLineId);
    if (flightActions) {
      // When swapping and the last action was also swapping, we can just remove the last swap action
      if (flightActions[flightActions.length - 1]?.actionType === FlightActionType.swapOptimisationTactic) {
        this.removeAction({
          actionType: FlightActionType.swapOptimisationTactic,
          flightLineId: payload.flightLineId,
          definedIndex: flightActions.length - 1,
        });
      } else {
        const action = new SwapOptimizationTacticAction(payload.cabinCode, payload.autopilot);
        this.addAction({ action, flightLineId: payload.flightLineId, joinAction: true });
      }
    }
  }

  @Action addRemoveOptimizationTacticAction(payload: {
    action: AddOptimizationProfileLevelAction | AddShadowOptimizationProfileLevelAction;
    flightLineId: number;
    shadow: boolean;
  }): void {
    // When we add a tactic action, usually we can remove the old add action or the remove tactic action.
    // One caveat is when a swap action is within the actions, then we want to keep the add or remove actions that happened before the last swap as the swaps need previous data to
    // keep the correct values
    // Example:
    // The original value for the active tactic is 4 and the shadow tactic is 8.
    // We set the active tactic to 2
    // We swap the tactics, so we'll get active: 8 and shadow: 2
    // Then we set the active to 1 > We need to keep the first set active action, otherwise the swap action will swap the wrong (original) value and then the shadow tactic will
    // have a wrong value

    const flightActions: FlightAction<any>[] | undefined = this.flightActions.get(payload.flightLineId);
    if (flightActions) {
      const lastSwapActionIndex: number = flightActions
        .map((action) => action.actionType)
        .lastIndexOf(FlightActionType.swapOptimisationTactic);

      if (lastSwapActionIndex !== -1) {
        const isOfActiveOptimisationActionType: FlightActionType =
          FlightActionType.addOptimisationProfileLevel || FlightActionType.removeOptimisationProfileLevel;
        const isOfShadowOptimisationActionType: FlightActionType =
          FlightActionType.addShadowOptimisationProfileLevel || FlightActionType.removeShadowOptimisationProfileLevel;

        const isOfCorrectOptimisationActionType: FlightActionType = payload.shadow
          ? isOfShadowOptimisationActionType
          : isOfActiveOptimisationActionType;
        flightActions
          .slice(lastSwapActionIndex, flightActions.length)
          .flatMap((action: FlightAction<any>, index) => {
            if (action.actionType === isOfCorrectOptimisationActionType) {
              return [{ actionType: action.actionType, index }];
            } else {
              return [];
            }
          })
          .forEach(({ actionType, index }) => {
            this.removeAction({ actionType, flightLineId: payload.flightLineId, definedIndex: lastSwapActionIndex + index });
          });
      }
      this.addAction({ ...payload, joinAction: true });
    }
  }

  @Action addRemoveShadowOptimizationTacticAction(payload: {
    action: AddOptimizationProfileLevelAction | AddShadowOptimizationProfileLevelAction;
    flightLineId: number;
    shadow: boolean;
  }): void {
    const existingOptimizationAction:
      | FlightAction<AddOptimizationProfileLevelAction | AddShadowOptimizationProfileLevelAction>
      | undefined = this.flightActions
      .get(payload.flightLineId)
      ?.find((action: FlightAction<any>) =>
        payload.shadow
          ? action.actionType === FlightActionType.addShadowOptimisationProfileLevel ||
            action.actionType === FlightActionType.removeShadowOptimisationProfileLevel
          : action.actionType === FlightActionType.addOptimisationProfileLevel ||
            action.actionType === FlightActionType.removeOptimisationProfileLevel,
      );
    if (existingOptimizationAction) {
      this.removeAction({ actionType: existingOptimizationAction.actionType, flightLineId: payload.flightLineId });
    }
    this.addAction(payload);
  }

  @Mutation
  public addAction(payload: { action: FlightAction<any>; flightLineId: number; joinAction?: boolean }) {
    const flightActions = this.flightActions.get(payload.flightLineId);
    if (flightActions) {
      const index = flightActions.findIndex(
        (action) => action.actionType === payload.action.actionType && action.cabinCode === payload.action.cabinCode,
      );
      if (index === -1 || payload.joinAction) {
        this.flightActions.set(payload.flightLineId, [...this.flightActions.get(payload.flightLineId), payload.action]);
        return;
      }
      this.flightActions.get(payload.flightLineId)?.splice(index, 1, payload.action);
    }
  }

  @Mutation
  public addMultiLegAction(payload: { action: FlightAction<any> }) {
    this.flightActions.forEach((value, key) => {
      const index = value.findIndex(
        (action) =>
          action.actionType === payload.action.actionType &&
          //@ts-ignore
          action.cabinCode === payload.action.cabinCode &&
          //@ts-ignore
          action.legNumber === payload.action.legNumber,
      );

      if (index === -1) {
        this.flightActions.set(key, [...this.flightActions.get(key), payload.action]);
        return;
      }

      this.flightActions.get(key).splice(index, 1, payload.action);
    });
  }

  @Mutation
  public addInventoryClassAction(payload: { action: FlightAction<any>; classCode: string }) {
    const index = this.flightActions.get(this.selectedFlightLine.id).findIndex(
      (action) =>
        action.actionType === payload.action.actionType &&
        action.cabinCode === payload.action.cabinCode &&
        // We make 1 request per class, so we can grab the first value
        (action.value as DistributedClasses).classes[0].code === payload.classCode,
    );

    if (index > -1) {
      this.flightActions.get(this.selectedFlightLine.id).splice(index, 1);
    }

    this.flightActions.get(this.selectedFlightLine.id).push(payload.action);
  }

  @Mutation
  public addDistributedClassAction(payload: { action: FlightAction<any>; classCode: string }) {
    const index = this.flightActions.get(this.selectedFlightLine.id).findIndex(
      (action) =>
        action.actionType === payload.action.actionType &&
        action.cabinCode === payload.action.cabinCode &&
        // We make 1 request per class, so we can grab the first value
        (action.value as DistributedClasses).classes[0].code === payload.classCode,
    );

    if (index > -1) {
      this.flightActions.get(this.selectedFlightLine.id).splice(index, 1);
    }

    this.flightActions.get(this.selectedFlightLine.id).push(payload.action);
  }

  @Mutation
  public addClassAction(payload: { action: FlightAction<any>; cabinCode: string; classCode: string }) {
    const index = this.flightActions.get(this.selectedFlightLine.id).findIndex(
      (action) =>
        action.actionType === payload.action.actionType &&
        action.cabinCode === payload.cabinCode &&
        // We make 1 request per class, so we can grab the first value
        (action.value as ClassValue[])[0].code === payload.classCode,
    );

    if (index > -1) {
      this.flightActions.get(this.selectedFlightLine.id).splice(index, 1);
    }

    this.flightActions.get(this.selectedFlightLine.id).push(payload.action);
  }

  @Mutation
  public removeAction(payload: { actionType: FlightActionType; flightLineId: number; definedIndex?: number }): void {
    const flightActions = this.flightActions?.get(payload.flightLineId);
    if (flightActions) {
      const index = payload.definedIndex || flightActions.findIndex((action) => action.actionType === payload.actionType);

      if (index === -1) {
        return;
      }

      flightActions.splice(index, 1);
    }
  }

  @Mutation
  public clearFlightActions() {
    this.flightActions = new Map();
  }

  @Mutation
  private setFlightLineRowModifiedToFalse() {
    this.flight = this.flight.map((flightLineInfo) => {
      flightLineInfo.cabins.map((cabin) =>
        cabin.classes.map((row) => {
          delete row.isModified;
        }),
      );

      return flightLineInfo;
    });
  }

  @Mutation
  private setFlightLineTagModifiedToFalse() {
    this.flight = this.flight.map((flightLineInfo) => {
      flightLineInfo.isModified = false;

      return flightLineInfo;
    });
  }

  @Mutation
  private resetUpdatedFlight() {
    this.updatedFlight = cloneDeep(this.flight);
  }

  @Mutation
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setSavingState(isSaving: boolean) {
    this.isSaving = isSaving;
  }

  @Mutation
  private setAttachedOptimisationProfileLevel(hasAttachedOptimisationProfileLevel: boolean) {
    this.hasAttachedOptimisationProfileLevel = hasAttachedOptimisationProfileLevel;
  }

  @Mutation
  private setValidAuStructure(hasValidAuStructure: boolean) {
    this.hasValidAuStructure = hasValidAuStructure;
  }

  @Mutation
  private setIsRecalculatingMutation(payload: boolean) {
    this.isRecalculating = payload;
  }

  @Mutation
  private addTagToSelectedFlightLine(tag: ITag) {
    this.selectedFlightLine.tags.push(tag);
  }

  get shouldDisplayContinuousPricingWarning() {
    const hasOnlyDiscreteClasses = this.selectedFlightLine.cabins.some((cabin) => cabin.classes.filter((cls) => !cls.discrete).length < 2);
    return CustomerSettingsModule.settings.hasContinuousPricingEnabled && hasOnlyDiscreteClasses;
  }
}

export const FlightModule = getModule(Flight);
