import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import {
  AllOptimisationProfilesModel,
  OptimisationProfileModel,
} from '@/modules/api/optimisation-profiles/optimisation-profiles-contracts';
import { optimisationProfileService } from '@/modules/api/optimisation-profiles/optimisation-profiles-service';
import { logger } from '@/modules/monitoring';
import { store } from '@/store';

// State definition
export interface IOptimisationProfilesState {
  allOptimisationProfiles: AllOptimisationProfilesModel;
}

const sortProfileLevels = (profile: OptimisationProfileModel[]): OptimisationProfileModel[] =>
  profile.map(({ id, name, description, active, levels }) => ({
    id,
    name,
    description,
    active,
    levels: levels.sort((a, b) => b.level - a.level),
  }));

@Module({
  dynamic: true,
  store,
  name: 'optimisationProfiles',
  namespaced: true,
})
class OptimisationProfiles extends VuexModule implements IOptimisationProfilesState {
  // Default state
  public allOptimisationProfiles: AllOptimisationProfilesModel = { profiles: [], byor: [], dsOp: [] };
  public combinedProfiles: OptimisationProfileModel[] = [];
  // Actions
  @Action
  public async getAll(): Promise<AllOptimisationProfilesModel | undefined> {
    try {
      const response = await optimisationProfileService.getAll();
      const sortedResponse: AllOptimisationProfilesModel = {
        profiles: sortProfileLevels(response.profiles),
        byor: sortProfileLevels(response.byor),
        dsOp: sortProfileLevels(response.dsOp),
      };

      this.setAll(sortedResponse);
      return sortedResponse;
    } catch (error) {
      logger.error(error as Error);
    }
  }

  @Action
  public async getForFlight(flightKey: string | undefined): Promise<AllOptimisationProfilesModel | undefined> {
    try {
      const response = await optimisationProfileService.getForFlight(flightKey);
      const sortedResponse: AllOptimisationProfilesModel = {
        byor: sortProfileLevels(response.byor),
        profiles: sortProfileLevels(response.profiles),
        dsOp: sortProfileLevels(response.dsOp),
      };

      return sortedResponse;
    } catch (error) {
      logger.error(error as Error);
    }
  }

  // Mutations
  @Mutation
  private setAll(payload: AllOptimisationProfilesModel): void {
    this.allOptimisationProfiles = { ...payload };
    this.combinedProfiles = [].concat(...Object.values(OptimisationProfilesModule.allOptimisationProfiles));
  }
}

export const OptimisationProfilesModule = getModule(OptimisationProfiles);
