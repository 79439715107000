import { CellClickedEvent } from 'ag-grid-community';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ColumnFunctionCallbackParams,
  EditableCallbackParams,
  ICellRendererParams,
  IsColumnFuncParams,
  NewValueParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { has, isFinite, isNull } from 'lodash-es';

import { ColumnId, generateClassLevelColumnId } from '@/models/enums/grid';
import { FlightViewFlightLineRow, FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { ClassStructure } from '@/modules/api/application/application-contracts';
import { InventoryGridModel, isCabinClass } from '@/modules/api/flight/inventory-grid-model';
import { UpdateAuthorizationUnitsAction } from '@/modules/flight-actions/actions/cabin-actions/update-authorization-units-action';
import { UpdateDiscontinueDateAction } from '@/modules/flight-actions/actions/cabin-actions/update-discontinue-date-action';
import { UpdateEffectiveDateAction } from '@/modules/flight-actions/actions/cabin-actions/update-effective-date-action';
import { UpdateMinControlAction } from '@/modules/flight-actions/actions/cabin-actions/update-min-control-action';
import { UpdateProtectionAction } from '@/modules/flight-actions/actions/cabin-actions/update-protections-action';
import { UpdateSegmentLimitAction } from '@/modules/flight-actions/actions/cabin-actions/update-segment-limit-action';
import {
  AvailabilityDistribution,
  ClassValue,
  DistributedClasses,
  FlightActionUpdateType,
  PinState,
} from '@/modules/flight-actions/api/flight-actions-contracts';
import { InventoryManagementMethodology } from '@/modules/shared/shared-contracts';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';
import { FlightModule } from '@/store/modules/flight.module';
import { UserConfigModule } from '@/store/modules/user-config.module';

const { t } = i18n.global;

function getSeatAvailability(data: FlightViewLegCabinInventoryTactic): number | undefined {
  return data.updatedSeatAvailability ? data.updatedSeatAvailability : data.seatAvailability;
}

function getProtection(data: { isProtectionUpdated?: boolean; updatedProtection?: number; protection?: number }): number | undefined {
  return data.isProtectionUpdated ? data.updatedProtection : data.protection;
}

const baseClassKpiColDef: ColDef = {
  sortable: true,
  lockPinned: true,
  menuTabs: ['filterMenuTab'],
  filter: 'agNumberColumnFilter',
  filterParams: { newRowsAction: 'keep' },
  type: 'numericColumn',
};

export const ClassCodeColumn: ColDef = {
  colId: ColumnId.ClassCode,
  headerName: t('code_short'),
  field: 'code',
  minWidth: 123,
  width: 123,
  hide: false,
  headerTooltip: t('code'),
  cellRenderer: 'ClassNameRenderer',
};

// TODO: (KB) FlightViewFlightLineRow is the type that is being used in the cellRenderer. So no other type should be possible!
export const BookingAvailabilityStatusColumn: ColDef<FlightViewFlightLineRow> = {
  colId: ColumnId.BookingAvailabilityStatus,
  headerName: t('bk_sa_status_short'),
  field: InventoryGridModel.bookings,
  type: 'numericColumn',
  width: 160,
  minWidth: 120,
  cellRenderer: 'BookingAvailabilityStatusRenderer',
  cellClass: 'marginless-cell',
  enableCellChangeFlash: false,
  headerTooltip: t('bk_sa_status'),
};

export const BoardingsColumn: ColDef = {
  colId: ColumnId.Boardings,
  headerName: t('boardings_short'),
  field: 'boardings',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('boardings'),
};

export const SoldColumn: ColDef = {
  colId: ColumnId.Sold,
  headerName: t('sold_short'),
  field: 'sold',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('sold'),
};

export const NoShowColumn: ColDef = {
  colId: ColumnId.NoShow,
  headerName: t('nos_short'),
  field: 'noShows',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('nos'),
};

export const GroupNoShowColumn: ColDef = {
  colId: ColumnId.GroupNoShow,
  headerName: t('gns_short'),
  field: 'groupNoShows',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('gns'),
};

export const OffloadedColumn: ColDef = {
  colId: ColumnId.Offloaded,
  headerName: t('off_loaded_short'),
  field: 'offloaded',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('off_loaded'),
};

export const GroupBoardingsColumn: ColDef = {
  colId: ColumnId.GroupBoardings,
  headerName: t('gbo_short'),
  field: 'groupBoardings',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('gbo'),
};

export const CommercialStandbyBoardingsColumn: ColDef = {
  colId: ColumnId.CommercialStandbyBoardings,
  headerName: t('cbo_short'),
  field: 'commercialBoardings',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('cbo'),
};

export const CommercialStandbyNoBoardingsColumn: ColDef = {
  colId: ColumnId.CommercialStandbyNoBoardings,
  headerName: t('cnb_short'),
  field: 'commercialStandby',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('cnb'),
};
export const UpgradesIntoColumn: ColDef = {
  colId: ColumnId.UpgradesInto,
  headerName: t('ugi_short'),
  field: 'upgradesInto',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('ugi'),
};
export const UpgradesOutColumn: ColDef = {
  colId: ColumnId.UpgradesOut,
  headerName: t('ugo_short'),
  field: 'upgradesOut',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('ugo'),
};

export const DowngradesIntoColumn: ColDef = {
  colId: ColumnId.DowngradesInto,
  headerName: t('dgi_short'),
  field: 'downgradesInto',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('dgi'),
};

export const DowngradesOutColumn: ColDef = {
  colId: ColumnId.DowngradesOut,
  headerName: t('dgo_short'),
  field: 'downgradesOut',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('dgo'),
};

export const GoShowColumn: ColDef = {
  colId: ColumnId.GoShow,
  headerName: t('gos_short'),
  field: 'goShow',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('gos'),
};

export const StaffStandbyBoardingsColumn: ColDef = {
  colId: ColumnId.StaffStandbyBoardings,
  headerName: t('sbo_short'),
  field: 'staffBoardings',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('sbo'),
};

export const StaffStandbyNoBoardingsColumn: ColDef = {
  colId: ColumnId.StaffStandbyNoBoardings,
  headerName: t('snb_short'),
  field: 'staffStandby',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('snb'),
};

export const VolunteersForOffloadColumn: ColDef = {
  colId: ColumnId.VolunteersForOffload,
  headerName: t('vol_short'),
  field: 'voluntaryOffload',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('vol'),
};

export const DeniedBoardingsColumn: ColDef = {
  colId: ColumnId.DeniedBoardings,
  headerName: t('dnb_short'),
  field: 'deniedBoardings',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('dnb'),
};

export const NoRecordColumn: ColDef = {
  colId: ColumnId.NoRecord,
  headerName: t('nor_short'),
  field: 'noRecord',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('nor'),
};

export const GroupSoldColumn: ColDef = {
  colId: ColumnId.GroupSold,
  headerName: t('grpsold_short'),
  field: 'groupSold',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('grpsold'),
};

export const NegoAllocationsColumn: ColDef = {
  colId: ColumnId.NegoAllocations,
  headerName: t('negoalloc_short'),
  field: 'negotiatedAllocations',
  minWidth: 50,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('negoalloc'),
};

// TODO: (KB) ColDef<FlightViewLegCabinInfo> | ColDef<FlightDepartedViewFlightLineRow> are the types that are expected in FlightLegCabinGrid.vue and FlightLineDepartedGrid.vue. But fareCurrency seems to be missing...
export const RevenueColumn: ColDef = {
  colId: ColumnId.Revenue,
  headerName: t('revenue_short'),
  field: 'revenue',
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  headerTooltip: t('revenue'),
  valueFormatter: (params: ValueFormatterParams) => {
    const revenue = params.value;

    if (revenue && params.data?.fareCurrency) {
      return FormatService.amountWithCurrency(revenue, params.data.fareCurrency);
    }

    return '';
  },
};

export const BoardedRevenueColumn: ColDef = {
  colId: ColumnId.BoardedRevenue,
  headerName: t('boarded_revenue_short'),
  field: InventoryGridModel.boardedRevenue,
  minWidth: 80,
  width: 80,
  type: 'numericColumn',
  headerTooltip: t('boarded_revenue'),
  valueFormatter: (params: ValueFormatterParams) => {
    const boardedRevenue = params.value;

    if (boardedRevenue && params.data.fareCurrency) {
      return FormatService.amountWithCurrency(boardedRevenue, params.data.fareCurrency);
    }

    return '';
  },
};

export const generateUpdatedSegmentLimitColumn = (isEditable = false): ColDef => ({
  colId: ColumnId.UpdatedSegmentLimit,
  headerName: t('sl_short'),
  type: 'numericColumn',
  field: InventoryGridModel.updatedSegmentLimit,
  minWidth: 35,
  width: 35,
  headerTooltip: t('sl'),
  cellEditor: isEditable ? 'GridNumericEditor' : undefined,
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.segmentLimit,
  },
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewFlightLineRow;
    const classes: ClassValue[] = [
      {
        code: data.code,
        value: event.newValue,
        pinned: null,
        clearValue: !!isNull(event.newValue),
      },
    ];

    const action = new UpdateSegmentLimitAction({
      cabinCode: event.data.cabinCode,
      classes,
      config: {
        inventoryConfigurationProperties: AppSettingsModule.inventoryConfigurationProperties,
        pssCapabilities: CustomerSettingsModule.settings.pssCapabilities,
        priceIncrementRange: CustomerSettingsModule.settings.priceIncrementRange,
      },
    });
    FlightModule.addClassAction({
      action,
      cabinCode: action.cabinCode,
      classCode: data.code,
    });
  },
  editable: (params: ColumnFunctionCallbackParams) => (isEditable ? (params.data as FlightViewFlightLineRow).isClassRow : false),
  cellClass: isEditable ? 'marginless-cell' : '',
});

export const UpdatedMinControlColumn: ColDef = {
  colId: ColumnId.UpdatedMinControl,
  headerName: t('min_control_short'),
  field: InventoryGridModel.updatedMinControl,
  minWidth: 80,
  width: 80,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('min_control'),
  cellEditor: 'GridNumericEditor',
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.minControl,
  },
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewFlightLineRow;
    const classes: ClassValue[] = [
      {
        code: data.code,
        value: event.newValue,
        pinned: null,
        clearValue: !!isNull(event.newValue),
      },
    ];

    const action = new UpdateMinControlAction({
      cabinCode: event.data.cabinCode,
      classes,
      config: {
        inventoryConfigurationProperties: AppSettingsModule.inventoryConfigurationProperties,
        pssCapabilities: CustomerSettingsModule.settings.pssCapabilities,
        priceIncrementRange: CustomerSettingsModule.settings.priceIncrementRange,
      },
    });
    FlightModule.addClassAction({
      action,
      cabinCode: action.cabinCode,
      classCode: data.code,
    });
  },
  editable: (event: EditableCallbackParams) => (event.data as FlightViewFlightLineRow).isClassRow,
  cellClass: 'marginless-cell',
};

export const UpdatedEffectiveDateColumn: ColDef = {
  colId: ColumnId.UpdatedEffectiveDate,
  headerName: t('updated_effective_date_short'),
  field: InventoryGridModel.updatedEffectiveDate,
  minWidth: 85,
  width: 85,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('updated_effective_date'),
  cellEditor: 'GridDateEditor',
  cellRenderer: 'GridDateChangeRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.effectiveDate,
  },
  singleClickEdit: true,
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewFlightLineRow;
    const classes: ClassValue[] = [
      {
        code: data.code,
        value: DateTimeService.StripUTCOffset({
          date: event.newValue,
        }),

        pinned: null,
        clearValue: !!isNull(event.newValue),
      },
    ];
    const action = new UpdateEffectiveDateAction({
      cabinCode: event.data.cabinCode,
      classes,
      config: {
        inventoryConfigurationProperties: AppSettingsModule.inventoryConfigurationProperties,
        pssCapabilities: CustomerSettingsModule.settings.pssCapabilities,
        priceIncrementRange: CustomerSettingsModule.settings.priceIncrementRange,
      },
    });
    FlightModule.addClassAction({
      action,
      cabinCode: action.cabinCode,
      classCode: data.code,
    });
  },
  valueFormatter: (params: ValueFormatterParams) => {
    if (params.value) {
      return FormatService.format('date', params.value);
    }
  },
  editable: (params: ColumnFunctionCallbackParams) => (params.data as FlightViewFlightLineRow).isClassRow,
  cellClass: 'marginless-cell',
};

export const UpdatedDiscontinueDateColumn: ColDef = {
  colId: ColumnId.UpdatedDiscontinueDate,
  headerName: t('updated_discontinued_date_short'),
  field: InventoryGridModel.updatedDiscontinuedDate,
  minWidth: 85,
  width: 85,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('updated_discontinued_date'),
  cellEditor: 'GridDateEditor',
  cellRenderer: 'GridDateChangeRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.discontinuedDate,
  },
  singleClickEdit: true,
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewFlightLineRow;
    const classes: ClassValue[] = [
      {
        code: data.code,
        value: DateTimeService.StripUTCOffset({
          date: event.newValue,
        }),
        pinned: null,
        clearValue: !!isNull(event.newValue),
      },
    ];

    const action = new UpdateDiscontinueDateAction({
      cabinCode: event.data.cabinCode,
      classes,
      config: {
        inventoryConfigurationProperties: AppSettingsModule.inventoryConfigurationProperties,
        pssCapabilities: CustomerSettingsModule.settings.pssCapabilities,
        priceIncrementRange: CustomerSettingsModule.settings.priceIncrementRange,
      },
    });
    FlightModule.addClassAction({
      action,
      cabinCode: action.cabinCode,
      classCode: data.code,
    });
  },
  valueFormatter: (params: ValueFormatterParams) => {
    if (params.value) {
      return FormatService.format('date', params.value);
    }
  },
  editable: (params: IsColumnFuncParams) => (params.data as FlightViewFlightLineRow).isClassRow,
  cellClass: 'marginless-cell',
};

export const ParentClassColumn: ColDef = {
  colId: ColumnId.ParentClass,
  headerName: t('parent_class_short'),
  field: InventoryGridModel.parentClass,
  minWidth: 90,
  width: 90,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('parent_class'),
};

export const EffectiveDateColumn: ColDef = {
  colId: ColumnId.EffectiveDate,
  headerName: t('c_effe_date_short'),
  field: InventoryGridModel.effectiveDate,
  minWidth: 85,
  width: 85,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('c_effe_date'),
  valueFormatter: (params: ValueFormatterParams) => {
    if (params.value) {
      return FormatService.format('date', params.value);
    }
  },
};

export const DiscontinuedDateColumn: ColDef = {
  colId: ColumnId.DiscontinueDate,
  headerName: t('c_disc_date_short'),
  field: InventoryGridModel.discontinuedDate,
  minWidth: 85,
  width: 85,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('c_disc_date'),
  valueFormatter: (params: ValueFormatterParams) => {
    if (params.value) {
      return FormatService.format('date', params.value);
    }
  },
};

export const SegmentAvailabilityColumn: ColDef = {
  colId: ColumnId.SegmentAvailability,
  headerName: t('c_sa_short'),
  field: InventoryGridModel.segmentAvailability,
  minWidth: 135,
  width: 135,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('captured_seat_availability'),
};

export const WaitListMaxColumn: ColDef = {
  colId: ColumnId.WaitListMax,
  headerName: t('c_wl_max_short'),
  field: InventoryGridModel.waitlistMax,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('c_wl_max'),
};

export const WaitListMaxPercentageColumn: ColDef = {
  colId: ColumnId.WaitListMaxPercentage,
  headerName: t('c_wl_max_perc_short'),
  field: InventoryGridModel.waitlistMaxPercentage,
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('c_wl_max_perc'),
};

export const GroupPendingColumn: ColDef = {
  colId: ColumnId.GroupPending,
  headerName: t('grp_op_short'),
  field: InventoryGridModel.groupPending,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('grp_op'),
};

export const GroupWaitlistedColumn: ColDef = {
  colId: ColumnId.GroupWaitlisted,
  headerName: t('grp_wl_short'),
  field: InventoryGridModel.groupWaitlisted,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('grp_wl'),
};

export const NegotiatedAllottedSeatsColumn: ColDef = {
  colId: ColumnId.NegotiatedAllottedSeats,
  headerName: t('nego_short'),
  field: InventoryGridModel.negotiatedAllottedSeats,
  minWidth: 60,
  width: 60,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('nego'),
};

export const UpdatedWaitlistMaxColumn: ColDef = {
  colId: ColumnId.UpdatedWaitlistMax,
  headerName: t('wl_max_short'),
  field: InventoryGridModel.updatedWaitlistMax,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  editable: false,
  headerTooltip: t('wl_max'),
};

export const UpdatedWaitlistMaxPercentageColumn: ColDef = {
  colId: ColumnId.UpdatedWaitlistMaxPercentage,
  headerName: t('wl_max_perc_short'),
  field: InventoryGridModel.updatedWaitlistMaxPercentage,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  editable: false,
  headerTooltip: t('wl_max_perc'),
};
export const generateCabinClassBookingsColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('bookings')}`,
  groupId: `${cabinCode}-bookings-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, ColumnId.Bookings),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.realTimeBookings !== undefined ? matchingClass.realTimeBookings : matchingClass.bookings;
    },
  })),
});

export const generateCabinClassAuthorizationUnitsColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('updated_authorization_units')}`,
  groupId: `${cabinCode}-upd-au-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'upd-au'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-au-${data.ondId}-${cabinCode}-${cls.code}`,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.isAuthorizationUnitsUpdated ? matchingClass.updatedAuthorizationUnits : matchingClass.authorizationUnits;
    },
  })),
});

export const generateCabinClassProtectionColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('pr')}`,
  groupId: `${cabinCode}-pr-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'pr'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 40,
    width: 40,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return getProtection(matchingClass);
    },
  })),
});

export const generateCabinClassSeatAvailabilityColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('seat_availability')}`,
  groupId: `${cabinCode}-sa-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'sa'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 40,
    width: 40,
    cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-sa-${data.ondId}-${cabinCode}-${cls.code}`,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.seatAvailability;
    },
  })),
});

export const generateCabinClassGroupBookingColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('grp_bk')}`,
  groupId: `${cabinCode}-grp-bk-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'grp-bk'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.groupBookings;
    },
  })),
});

export const generateCabinClassSegmentLimitColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('sl')}`,
  groupId: `${cabinCode}-sl-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'sl'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.isSegmentLimitUpdated ? matchingClass.updatedSegmentLimit : matchingClass.segmentLimit;
    },
  })),
});

export const generateUpdatedDiscontinuedDateColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('updated_discontinued_date')}`,
  groupId: `${cabinCode}-disc-date-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'disc-date'),
    ...baseClassKpiColDef,
    filter: 'agDateColumnFilter',
    headerName: cls.code,
    minWidth: 85,
    width: 85,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value) {
        return FormatService.format('date', params.value);
      }
    },
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.isDiscontinuedDateUpdated ? matchingClass.updatedDiscontinuedDate : matchingClass.discontinuedDate;
    },
  })),
});
export const generateUpdatedEffectiveDateColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('updated_effective_date')}`,
  groupId: `${cabinCode}-effe-date-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'effe-date'),
    ...baseClassKpiColDef,
    filter: 'agDateColumnFilter',
    headerName: cls.code,
    minWidth: 85,
    width: 85,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value) {
        return FormatService.format('date', params.value);
      }
    },
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.isEffectiveDateUpdated ? matchingClass.updatedEffectiveDate : matchingClass.effectiveDate;
    },
  })),
});
export const generateUpdatedMinControlColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('updated_min_control')}`,
  groupId: `${cabinCode}-min-control-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'min-control'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.isMinControlUpdated ? matchingClass.updatedMinControl : matchingClass.minControl;
    },
  })),
});
export const generateWaitlistMaxColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('wl_max')}`,
  groupId: `${cabinCode}-waitlist-max-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'waitlist-max'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.updatedWaitlistMax ? matchingClass.updatedWaitlistMax : matchingClass.waitlistMax;
    },
  })),
});
export const generateWaitlistMaxPercentage = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('wl_max_perc')}`,
  groupId: `${cabinCode}-waitlist-max-pct-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'waitlist-max-pct'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 40,
    width: 40,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.updatedWaitlistMaxPercentage ? matchingClass.updatedWaitlistMaxPercentage : matchingClass.waitlistMaxPercentage;
    },
  })),
});
export const generateWaitlistColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('wl')}`,
  groupId: `${cabinCode}-waitlist-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'waitlist'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.waitlist;
    },
  })),
});
export const generateGroupWaitlistColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('grp_wl')}`,
  groupId: `${cabinCode}-group-waitlist-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'group-waitlist'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.groupWaitlisted;
    },
  })),
});
export const generateCapturedGroupOptionalColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColGroupDef => ({
  headerName: `${t('general.flight')} | ${t('cabin')} ${cabinCode} - ${t('grp_op')}`,
  groupId: `${cabinCode}-group-optional-group`,
  children: cabinClasses.map((cls) => ({
    colId: generateClassLevelColumnId(cabinCode, cls.code, 'group-optional'),
    ...baseClassKpiColDef,
    headerName: cls.code,
    minWidth: 35,
    width: 35,
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (!matchingCabin) {
        return;
      }
      const matchingClass = FlightService.getMatchedClass(matchingCabin, cls.code);
      if (!matchingClass) {
        return;
      }
      return matchingClass.groupOptional;
    },
  })),
});

export const FlightLineCodeColumn: ColDef = {
  colId: ColumnId.FlightLineCode,
  headerName: t('flight_line_code_short'),
  field: InventoryGridModel.code,
  minWidth: 110,
  width: 110,
  hide: false,
  headerTooltip: t('general.flight_line'),
  cellRenderer: 'ClassNameRenderer',
  cellClass: ({ data }) => {
    const classes: string[] = [''];
    if (!data.isDiscrete) {
      classes.push(`data-test-non-discrete-flight-line-code-column`);
    }
    return classes.join(' ');
  },
};

export const FareValueColumn: ColDef = {
  colId: ColumnId.FareValue,
  headerName: t('fare_value'),
  field: InventoryGridModel.fareValue,
  minWidth: 100,
  type: 'numericColumn',
  hide: true,
  valueFormatter: (params: ValueFormatterParams) => {
    const fare = params.value;
    if (isFinite(fare)) {
      return FormatService.amountWithCurrency(fare, params.data.fareCurrency);
    }
    return '';
  },
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];
    if (!data.isDiscrete) {
      classes.push(`data-test-non-discrete-fare-value-column`);
    }
    return classes.join(' ');
  },
  headerTooltip: t('fare_value'),
};

export const PublishedFareColumn: ColDef = {
  colId: ColumnId.PublishedFare,
  headerName: t('published_fare_short'),
  field: InventoryGridModel.publishedFareValue,
  minWidth: 100,
  type: 'numericColumn',
  hide: true,
  valueFormatter: (params: ValueFormatterParams) => {
    const fare = params.value;

    if (isFinite(fare)) {
      return FormatService.amountWithCurrency(fare, params.data.fareCurrency);
    }

    return '';
  },
  headerTooltip: t('published_fare'),
};

export const MinPriceColumn: ColDef = {
  colId: ColumnId.MinPrice,
  headerName: t('min_price_short'),
  field: InventoryGridModel.minPrice,
  minWidth: 100,
  type: 'numericColumn',
  valueFormatter: (params: ValueFormatterParams) => {
    const minPrice = params.value;

    if (isFinite(minPrice)) {
      return FormatService.amountWithCurrency(minPrice, params.data.fareCurrency);
    }

    return '';
  },
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];
    if (!data.isDiscrete) {
      classes.push(`data-test-non-discrete-min-price-column`);
    }
    return classes.join(' ');
  },
  headerTooltip: t('min_price'),
};
export const MaxPriceColumn: ColDef = {
  colId: ColumnId.MaxPrice,
  headerName: t('max_price_short'),
  field: InventoryGridModel.maxPrice,
  minWidth: 100,
  type: 'numericColumn',
  valueFormatter: (params: ValueFormatterParams) => {
    const maxPrice = params.value;
    if (isFinite(maxPrice)) {
      return FormatService.amountWithCurrency(maxPrice, params.data.fareCurrency, 2);
    }
    return '';
  },
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];
    if (!data.isDiscrete) {
      classes.push(`data-test-non-discrete-max-price-column`);
    }
    return classes.join(' ');
  },
  headerTooltip: t('max_price'),
};

export const UpdatedAuthorizationUnitsColumn = (isEditable: boolean, width = 35): ColDef => ({
  colId: ColumnId.UpdatedAuthorizationUnits,
  headerName: t('updated_authorization_units_short'),
  field: InventoryGridModel.updatedAuthorizationUnits,
  minWidth: width,
  type: 'numericColumn',
  hide: false,
  editable: (params: IsColumnFuncParams) => isEditable && params.data.isClassRow && !isCabinClass(params.data.classIndex),
  headerTooltip: t('updated_authorization_units'),
  cellClass: ({ data }) => {
    const classes: string[] = ['marginless-cell'];

    if (has(data, InventoryGridModel.updatedAuthorizationUnits)) {
      classes.push(
        `data-test-updated-au-cell-cabin-${data.cabinCode.toLowerCase()}-flight-line-${data[InventoryGridModel.code]?.toLowerCase()}`,
      );
      if (!data.isDiscrete) {
        classes.push(`data-test-non-discrete-authorization-unit-column`);
      }
    }

    return classes.join(' ');
  },
  cellRenderer: 'GridAuPrDetailsRenderer',
  cellEditor: 'GridAuPrEditor',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.authorizationUnits,
    isEditable,
  },
  onCellClicked: (event: CellClickedEvent) => {
    const data = event.data as FlightViewFlightLineRow;
    if (UserConfigModule.inventoryManagementMethodology === InventoryManagementMethodology.au && data.modified) {
      const classes: DistributedClasses = {
        classes: [
          {
            code: data.code,
            value: data.updatedAuthorizationUnits,
            type: FlightActionUpdateType.AuBased,
            clearValue: false,
            pinned: data.isAuthorizationUnitPinned ? PinState.PIN : PinState.UNPIN,
          },
        ],
        // TODO: what is the default?
        distribution: AvailabilityDistribution.MAINTAIN_PROTECTIONS,
      };

      const action = new UpdateAuthorizationUnitsAction(data.cabinCode, classes);

      FlightModule.addInventoryClassAction({
        action,
        classCode: data.code,
      });
    }
  },
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewFlightLineRow;

    const classes: DistributedClasses = {
      classes: [
        {
          code: data.code,
          value: data.updatedAuthorizationUnits,
          type: FlightActionUpdateType.AuBased,
          clearValue: false,
          pinned: data.isAuthorizationUnitPinned ? PinState.PIN : PinState.UNPIN,
        },
      ],
      // TODO: what is the default?
      distribution: AvailabilityDistribution.MAINTAIN_PROTECTIONS,
    };

    const action = new UpdateAuthorizationUnitsAction(event.data.cabinCode, classes);
    FlightModule.addInventoryClassAction({
      action,
      classCode: data.code,
    });
  },
});

export const UpdatedProtectionColumn = (isEditable: boolean, width = 35): ColDef => ({
  colId: ColumnId.UpdatedProtection,
  headerName: t('pr_short'),
  field: InventoryGridModel.updatedProtection,
  minWidth: width,
  type: 'numericColumn',
  hide: false,
  editable: (params: IsColumnFuncParams) => isEditable && params.data.isClassRow && !isCabinClass(params.data.classIndex),
  cellEditor: 'GridAuPrEditor',
  cellClass: 'marginless-cell',
  headerTooltip: t('pr'),
  cellRenderer: 'GridAuPrDetailsRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.protection,
    isEditable,
  },
  onCellClicked: (event: CellClickedEvent) => {
    const data = event.data as FlightViewFlightLineRow;
    if (UserConfigModule.inventoryManagementMethodology === InventoryManagementMethodology.pr && data.modified) {
      const classes: DistributedClasses = {
        classes: [
          {
            code: data.code,
            value: data.updatedProtection,
            type: FlightActionUpdateType.PrBased,
            clearValue: false,
            pinned: data.isProtectionPinned ? PinState.PIN : PinState.UNPIN,
          },
        ],
        // TODO: what is the default?
        distribution: AvailabilityDistribution.MAINTAIN_PROTECTIONS,
      };

      const action = new UpdateProtectionAction(data.cabinCode, classes);
      FlightModule.addInventoryClassAction({
        action,
        classCode: data.code,
      });
    }
  },
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewFlightLineRow;
    const classes: DistributedClasses = {
      classes: [
        {
          code: data.code,
          value: data.updatedProtection,
          type: FlightActionUpdateType.PrBased,
          clearValue: false,
          pinned: data.isProtectionPinned ? PinState.PIN : PinState.UNPIN,
        },
      ],
      // TODO: what is the default?
      distribution: AvailabilityDistribution.MAINTAIN_PROTECTIONS,
    };

    const action = new UpdateProtectionAction(data.cabinCode, classes);
    FlightModule.addInventoryClassAction({
      action,
      classCode: data.code,
    });
  },
});

export const UpdatedReadOnlyAuthorizationUnitsColumn = (isEditable: boolean, width = 35): ColDef => ({
  headerName: t('updated_authorization_units_short'),
  field: InventoryGridModel.updatedAuthorizationUnits,
  minWidth: width,
  type: 'numericColumn',
  colId: '212',
  hide: false,
  editable: false,
  headerTooltip: t('updated_authorization_units'),
  cellEditor: 'GridNumericEditor',
  cellClass: isEditable ? 'marginless-cell' : '',
  cellRenderer: 'GridAuPrRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.authorizationUnits,
    isEditable,
  },
});
export const UpdatedReadOnlyProtectionColumn = (isEditable: boolean, width = 35): ColDef => ({
  headerName: t('pr_short'),
  field: InventoryGridModel.updatedProtection,
  minWidth: width,
  type: 'numericColumn',
  colId: '216',
  hide: false,
  editable: false,
  cellEditor: 'GridNumericEditor',
  cellClass: isEditable ? 'marginless-cell' : '',
  headerTooltip: t('pr'),
  cellRenderer: 'GridAuPrRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.protection,
    isEditable,
  },
});

export const SeatAvailabilityColumn = (showDiff = false): ColDef => ({
  colId: ColumnId.SeatAvailability,
  headerName: t('seat_availability_short'),
  field: InventoryGridModel.updatedSeatAvailability,
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('sa'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: showDiff ? InventoryGridModel.seatAvailability : InventoryGridModel.updatedSeatAvailability,
  },
  cellClass: ({ data }) => {
    const classes: string[] = [];
    if (has(data, InventoryGridModel.updatedSeatAvailability)) {
      classes.push(
        `data-test-updated-sa-cell-cabin-${data.cabinCode.toLowerCase()}-flight-line-${data[InventoryGridModel.code]?.toLowerCase()}`,
      );
      if (!data.isDiscrete) {
        classes.push(`data-test-non-discrete-seat-availability-column`);
      }
    }

    return classes.join(' ');
  },
});

export const RecommendedSeatAvailabilityColumn: ColDef = {
  colId: ColumnId.RecommendedSeatAvailability,
  headerName: t('recommended_seat_availability_short'),
  field: InventoryGridModel.recommendedSeatAvailability,
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('rsa'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.recommendedSeatAvailability,
  },
  cellClass: ({ data }) => {
    const classes: string[] = [];
    if (has(data, InventoryGridModel.recommendedSeatAvailability)) {
      classes.push(
        `data-test-recommended-seat-availability-cell-cabin-${data.cabinCode?.toLowerCase()}-flight-line-${data[
          InventoryGridModel.code
        ]?.toLowerCase()}`,
      );
    }

    if (!data.isDiscrete) {
      classes.push(`data-test-non-discrete-recommended-seat-availability-column`);
    }

    return classes.join(' ');
  },
};

export const RecommendedSeatAvailabilityDifferenceColumn: ColDef = {
  colId: ColumnId.RecommendedSeatAvailabilityDifference,
  headerName: t('recommended_seat_availability_difference_short'),
  field: InventoryGridModel.recommendedSeatAvailabilityDifference,
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('rsa_difference'),
  cellRenderer: 'GridValueDifferenceRenderer',
  cellClass: 'graph-difference-style-1',
  cellRendererParams: (params: ICellRendererParams) => ({
    compareWithValue: params.data.recommendedSeatAvailability,
    compareToValue: getSeatAvailability(params.data),
    lowestDifference: params.data.lowestSeatAvailabilityDifference,
    highestDifference: params.data.highestSeatAvailabilityDifference,
    compareWithType: t('recommended_seat_availability_short'),
    compareToType: t('seat_availability_short'),
    zeroFixedOnCenter: true,
  }),
};

export const ShadowRecommendedSeatAvailabilityColumn: ColDef = {
  colId: ColumnId.ShadowRecommendedSeatAvailability,
  headerName: t('recommended_seat_availability_short'),
  field: InventoryGridModel.shadowRecommendedSeatAvailability,
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('rsa'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.shadowRecommendedSeatAvailability,
  },
  cellClass: ({ data }) => {
    const classes: string[] = [];
    if (has(data, InventoryGridModel.shadowRecommendedSeatAvailability)) {
      classes.push(
        `data-test-shadow-recommended-seat-availability-cell-cabin-${data.cabinCode?.toLowerCase()}-flight-line-${data[
          InventoryGridModel.code
        ]?.toLowerCase()}`,
      );
    }

    if (!data.isDiscrete) {
      classes.push(`data-test-non-discrete-shadow-recommended-seat-availability-column`);
    }

    return classes.join(' ');
  },
};

export const ShadowRecommendedSeatAvailabilityDifferenceColumn: ColDef = {
  colId: ColumnId.ShadowRecommendedSeatAvailabilityDifference,
  headerName: t('recommended_seat_availability_difference_short'),
  field: InventoryGridModel.shadowRecommendedSeatAvailabilityDifference,
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('rsa_difference'),
  cellClass: 'graph-difference-style-1',
  cellRenderer: 'GridValueDifferenceRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({
    compareWithValue: params.data.shadowRecommendedSeatAvailability,
    compareToValue: getSeatAvailability(params.data),
    lowestDifference: params.data.shadowLowestSeatAvailabilityDifference,
    highestDifference: params.data.shadowHighestSeatAvailabilityDifference,
    compareWithType: t('recommended_seat_availability_short'),
    compareToType: t('seat_availability_short'),
    zeroFixedOnCenter: true,
  }),
};

export const RuleAuthorizationUnitsColumn: ColDef = {
  colId: ColumnId.RuleAuthorizationUnits,
  headerName: t('rule_authorization_units_short'),
  field: InventoryGridModel.ruleAuthorizationUnits,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('rule_authorization_units'),
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];
    if (!data.isDiscrete) {
      classes.push(`data-test-non-discrete-rule-au-column`);
    }
    return classes.join(' ');
  },
};

export const BookingsColumn: ColDef = {
  colId: ColumnId.Bookings,
  headerName: t('bookings_short'),
  field: InventoryGridModel.updatedBookings,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('bookings'),
  cellRenderer: 'GridChangeRenderer',
  cellRendererParams: {
    originalValueKey: InventoryGridModel.bookings,
  },
};

export const TotalFareRevenueColumn: ColDef<FlightViewFlightLineRow> = {
  colId: ColumnId.TotalFareRevenue,
  headerName: t('revenue_short'),
  headerTooltip: t('revenue'),
  field: 'totalRevenue.fare.amount',
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  valueFormatter: (params: ValueFormatterParams<FlightViewFlightLineRow, number>) => {
    const revenue = params.value;

    if (revenue && params.data?.fareCurrency) {
      return FormatService.amountWithCurrency(revenue, params.data.fareCurrency);
    }

    return '';
  },
};

export const AverageFareColumn: ColDef = {
  colId: ColumnId.AverageFare,
  headerName: t('average_fare_short'),
  field: InventoryGridModel.averageFare,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('average_fare'),
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;

    if (isFinite(averageFare) && params.data.fareCurrency) {
      return FormatService.amountWithCurrency(averageFare, params.data.fareCurrency);
    }

    return '';
  },
};

export const AuthorizationUnitsColumn: ColDef = {
  colId: ColumnId.AuthorizationUnits,
  headerName: t('c_au_short'),
  field: InventoryGridModel.authorizationUnits,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('c_au'),
  cellClass: ({ data }) => `${!data.isDiscrete ? 'data-test-non-discrete-c-authorization-units-column' : ''} ag-right-aligned-cell`,
};

export const ProtectionColumn: ColDef = {
  colId: ColumnId.Protection,
  headerName: t('c_pr_short'),
  field: InventoryGridModel.protection,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: false,
  headerTooltip: t('c_pr'),
};

export const GroupBookingsColumn: ColDef = {
  colId: ColumnId.GroupBookings,
  headerName: t('grp_bk_short'),
  field: InventoryGridModel.groupBookings,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('grp_bk'),
};

export const WaitlistColumn: ColDef = {
  colId: ColumnId.Waitlist,
  headerName: t('wl_short'),
  field: InventoryGridModel.waitlist,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  hide: true,
  headerTooltip: t('wl'),
};

export const RecommendedAuthorizationUnitsColumn: ColDef = {
  colId: ColumnId.RecommendedAuthorizationUnits,
  headerName: t('r_au_short'),
  field: InventoryGridModel.recommendedAuthorizationUnits,
  type: 'numericColumn',
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];

    if (has(data, InventoryGridModel.recommendedAuthorizationUnits)) {
      classes.push(
        `data-test-recommended-au-cell-cabin-${data.cabinCode.toLowerCase()}-flight-line-${data[InventoryGridModel.code]?.toLowerCase()}`,
      );
      if (!data.isDiscrete) {
        classes.push(`data-test-non-discrete-recommended-authorization-unit-column`);
      }
    }
    return classes.length > 0 ? classes.join(' ') : '';
  },
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('r_au'),
};

export const ShadowRecommendedAuthorizationUnitsColumn: ColDef = {
  colId: ColumnId.ShadowRecommendedAuthorizationUnits,
  headerName: t('r_au_short'),
  field: InventoryGridModel.shadowRecommendedAuthorizationUnits,
  type: 'numericColumn',
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];

    if (has(data, InventoryGridModel.shadowRecommendedAuthorizationUnits)) {
      classes.push(
        `data-test-shadow-recommended-au-cell-cabin-${data.cabinCode.toLowerCase()}-flight-line-${data[
          InventoryGridModel.code
        ]?.toLowerCase()}`,
      );
      if (!data.isDiscrete) {
        classes.push(`data-test-non-discrete-shadow-recommended-authorization-unit-column`);
      }
    }
    return classes.length > 0 ? classes.join(' ') : '';
  },
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('r_au'),
};

export const RecommendedProtectionColumn: ColDef = {
  colId: ColumnId.RecommendedProtection,
  headerName: t('r_pr_short'),
  field: InventoryGridModel.recommendedProtection,
  type: 'numericColumn',
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];

    if (has(data, InventoryGridModel.shadowRecommendedAuthorizationUnits)) {
      classes.push(
        `data-test-recommended-pr-cell-cabin-${data.cabinCode.toLowerCase()}-flight-line-${data[InventoryGridModel.code]?.toLowerCase()}`,
      );
      if (!data.isDiscrete) {
        classes.push(`data-test-non-discrete-recommended-protection-column`);
      }
    }
    return classes.length > 0 ? classes.join(' ') : '';
  },
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('r_pr'),
};

export const RecommendedProtectionDifferenceColumn: ColDef = {
  colId: ColumnId.RecommendedProtectionDifference,
  headerName: t('r_pr_difference_short'),
  field: InventoryGridModel.recommendedProtectionDifference,
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('r_pr_difference'),
  cellClass: 'graph-difference-style-2',
  cellRenderer: 'GridValueDifferenceRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({
    compareWithValue: params.data.recommendedProtection,
    compareToValue: getProtection(params.data),
    lowestDifference: params.data.lowestProtectionDifference,
    highestDifference: params.data.highestProtectionDifference,
    compareWithType: t('r_pr_short'),
    compareToType: t('pr_short'),
    zeroFixedOnCenter: true,
  }),
};

export const ShadowRecommendedProtectionColumn: ColDef = {
  colId: ColumnId.ShadowRecommendedProtection,
  headerName: t('r_pr_short'),
  field: InventoryGridModel.shadowRecommendedProtection,
  type: 'numericColumn',
  cellClass: ({ data }) => {
    const classes: string[] = ['ag-right-aligned-cell'];

    if (has(data, InventoryGridModel.shadowRecommendedAuthorizationUnits)) {
      classes.push(
        `data-test-shadow-recommended-pr-cell-cabin-${data.cabinCode.toLowerCase()}-flight-line-${data[
          InventoryGridModel.code
        ]?.toLowerCase()}`,
      );
      if (!data.isDiscrete) {
        classes.push(`data-test-non-discrete-shadow-recommended-protection-column`);
      }
    }
    return classes.length > 0 ? classes.join(' ') : '';
  },
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('r_pr'),
};

export const ShadowRecommendedProtectionDifferenceColumn: ColDef = {
  colId: ColumnId.ShadowRecommendedProtectionDifference,
  headerName: t('r_pr_difference_short'),
  field: InventoryGridModel.shadowRecommendedProtectionDifference,
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: false,
  headerTooltip: t('r_pr_difference'),
  cellClass: 'graph-difference-style-2',
  cellRenderer: 'GridValueDifferenceRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({
    compareWithValue: params.data.shadowRecommendedProtection,
    compareToValue: getProtection(params.data),
    lowestDifference: params.data.shadowLowestProtectionDifference,
    highestDifference: params.data.shadowHighestProtectionDifference,
    compareWithType: t('r_pr_short'),
    compareToType: t('pr_short'),
    zeroFixedOnCenter: true,
  }),
};

export const LowestAvailableFareClassColumn: ColDef = {
  colId: ColumnId.LowestAvailableFareClass,
  headerName: t('lowest_available_fare_short'),
  field: 'lowestAvailableFareClass',
  type: 'rightAligned',
  width: 40,
  minWidth: 40,
  cellClass: (params: CellClassParams<FlightViewLegCabinInventoryTactic>) => {
    if (params.data) {
      const seatAvailability = getSeatAvailability(params.data);

      if (seatAvailability && seatAvailability < 1) {
        return 'cell-laf cell-laf--soldout';
      }
    }

    if (params.value) {
      return 'cell-laf';
    }

    return '';
  },
  headerTooltip: t('lowest_available_fare'),
  valueGetter: (params: ValueGetterParams<FlightViewLegCabinInventoryTactic>) => {
    if (params.data) {
      const seatAvailability = getSeatAvailability(params.data);

      if (seatAvailability && seatAvailability < 1) {
        return t('sold_out');
      }
    }

    return params.data?.lowestAvailableFareClass;
  },
};

export const RecommendedLowestAvailableFareClassColumn: ColDef = {
  colId: ColumnId.RecommendedLowestAvailableFareClass,
  headerName: t('recommended_lowest_available_fare_short'),
  field: 'recommendedLowestAvailableFareClass',
  type: 'numericColumn',
  width: 40,
  minWidth: 40,
  headerTooltip: t('recommended_lowest_available_fare'),
  cellRenderer: 'GridRecommendedLAFRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({
    recommendedSeatAvailability: params.data.recommendedSeatAvailability,
    recommendedLowestAvailableFareClass: params.data.recommendedLowestAvailableFareClass,
    activeLowestAvailableFareClass: params.data.lowestAvailableFareClass,
    cabinCode: params.data.cabinCode,
    shadow: false,
  }),
};

export const ShadowRecommendedLowestAvailableFareClassColumn: ColDef = {
  colId: ColumnId.ShadowRecommendedLowestAvailableFareClass,
  headerName: t('recommended_lowest_available_fare_short'),
  field: 'shadowRecommendedLowestAvailableFareClass',
  type: 'numericColumn',
  width: 40,
  minWidth: 40,
  headerTooltip: t('recommended_lowest_available_fare'),
  cellRenderer: 'GridRecommendedLAFRenderer',
  cellRendererParams: (params: ICellRendererParams) => ({
    recommendedSeatAvailability: params.data.shadowRecommendedSeatAvailability,
    recommendedLowestAvailableFareClass: params.data.shadowRecommendedLowestAvailableFareClass,
    activeLowestAvailableFareClass: params.data.lowestAvailableFareClass,
    cabinCode: params.data.cabinCode,
    shadow: true,
  }),
};

export const OwnFareColumn: ColDef = {
  colId: ColumnId.FarePrice,
  headerName: t('own_fare'),
  type: 'numericColumn',
  width: 40,
  minWidth: 40,
  field: 'ownFare',
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const invTacticRow = params.data as FlightViewLegCabinInventoryTactic;

    if (isFinite(invTacticRow.ownFare)) {
      return FormatService.amountWithCurrency(invTacticRow.ownFare, invTacticRow.fareCurrency);
    }
  },
  headerTooltip: t('own_fare'),
};
